

















import { Vue, Component } from 'vue-property-decorator';
import EventSelect from '@/components/EventSelect.vue';
import TableComponent from './components/Table.vue';

@Component({
  components: {
    EventSelect,
    TableComponent,
  },
})
export default class extends Vue {}
