




































import { Vue, Component, Ref } from 'vue-property-decorator';
import { API } from '@/types';
import Modify from './Modify.vue';
import dayjs from 'dayjs';
import { msToTimeStr } from '@/util';
import { rootModule } from '@/store';
import ActiveState from './ActiveState.vue';
import DataTable from '@/components/Admin/DataTable.vue';

@Component({
  components: {
    DataTable,
    ActiveState,
  },
})
export default class extends Vue {
  @Ref('DataTable') dataTable!: DataTable;
  casters: API.Casters.GET[] = []; // Only stores the current page of casters!
  tableOptions = { page: 1, itemsPerPage: 10 };
  modifyCaster: API.Casters.GET | null = null; // Caster to be modified/deleted.
  options = {
    apiName: 'casters',
    socketName: 'casterModified',
    idStr: 'id',
    embeds: [
      { name: 'user', socketName: 'userModified', idStr: 'userId' },
      { name: 'event', socketName: 'eventModified', idStr: 'eventId' },
    ],
    headers: [
      { text: 'Name', value: 'user.name' },
      { text: 'Event', value: 'event.name' },
      { text: 'Created', value: 'creationTimestamp' },
      { text: 'Active State', value: 'active' },
    ],
    modifyComponent: Modify,
  };
  dayjs = dayjs;
  msToTimeStr = msToTimeStr;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }
}
