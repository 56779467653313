











import { apiModify } from '@/api';
import { API } from '@/types';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @Prop(Object) readonly caster!: API.Casters.GET;
  active = false;
  changing = false;

  created(): void {
    this.active = this.caster.active;
  }

  @Watch('caster.active')
  onCasterActiveChange(val: boolean): void {
    this.active = val;
  }

  async change(state: boolean): Promise<void> {
    this.changing = true;
    try {
      const { data } = (await apiModify('casters', this.caster.id, { active: state }));
      this.active = data.active;
      this.$emit('post', data);
    } catch (err) {
      this.active = this.caster.active;
    }
    this.changing = false;
  }
}
